import React, { useCallback } from "react";

import { A11y } from "@churchofjesuschrist/eden-buttons";
import {
  blue10,
  black,
  spacing8,
  spacing16
} from "@churchofjesuschrist/eden-style-constants";
import { Card } from "@churchofjesuschrist/eden-tile-parts";
import styled from "styled-components";

import useTranslation from "../../../utils/use-translations";

const BannerCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  background-color: ${blue10};
  color: ${black};
  padding: 0;
  z-index:100;
`;

const InstallSection = styled(A11y)`
  flex-grow: 1;
  padding: ${spacing16} 0 ${spacing16} ${spacing16};
`;

const CloseSection = styled(A11y)`
  padding: ${spacing16} ${spacing16} ${spacing16} ${spacing8};
`;

const stringGroups = [
  {
    name:"shared",
    keys:[
      "addToHomeScreen"]
  }
];

const PwaBanner = ({installCallback = () => {}, closeCallback = () => {}, ...others}) => {
  const {managedStrings} = useTranslation(stringGroups);

  const closeClick = useCallback(() => {
    closeCallback();
  }, [closeCallback])

  return (
    <BannerCard {...others}>
      <InstallSection onClick={installCallback}>
        {managedStrings.addToHomeScreen}
      </InstallSection>
      <CloseSection onClick={closeClick}>
        X
      </CloseSection>
    </BannerCard>
  );
};

export default PwaBanner;