import { useEffect, useState, useCallback} from "react";

import {useNavigate} from "react-router-dom";

import userService, {getImpersonatingUser} from "./user-service";
import stringHasValue from "../../../utils/string-has-value";
import useStatus from "../../generic/hooks/use-status/use-status";



const REDIRECT_KEY = "redirectRoute";
const DASHBOARD = "/student/dashboard";
const SIGNON = "/requiresignon";
const SIGNOUT = "/signout"

const defaultService = userService();

const useSignedOnUser = (service = defaultService) => {

  const [user,setUser] = useState();
  const [userLoaded, setUserLoaded] = useState(false);
  const navigate = useNavigate();
  const [pathwayPrompted, setPathwayPrompted] = useState();
  const pathwayPromptKey = "pathwayPromptDismissed";

  const loadUser = useCallback(async () => {
    try {

      const loaded = await service.loadUser();
      //note: impersonation permission flags are loaded on the user object. We have no need of further permission information
      setUser(loaded);
      setUserLoaded(true);
      const hasDismissedPathway = window.sessionStorage.getItem(pathwayPromptKey, true);
      setPathwayPrompted(!hasDismissedPathway && loaded?.isPathway);

    } catch (e) {
      setUser(undefined);
      setUserLoaded(false);
    }

  },[service]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const reloadAfterStudentCreated = useCallback(async () => {
    setUserLoaded(false);
    await loadUser();
  },[loadUser])



  const requireLoginForRoute = useCallback((routeToReturnTo) => {
    if (!userLoaded) {
      throw new Error("attempted to require sign before site finished loading the user");
    }
    if (!user) {
      window.localStorage.setItem(REDIRECT_KEY, routeToReturnTo);
      window.location = SIGNON;
      //navigate(SIGNON)
    }//if we have a user, we don't do anything.
  },[user, userLoaded]);

  const signInFromMenuLink = useCallback(() => {
    requireLoginForRoute(DASHBOARD);
  },[requireLoginForRoute]);

  const signOut = useCallback(() => {
    //window.location is a clean sandbox so we start this whole
    //page fresh so no need to setUser or other stuff like that.
    service.setImpersonatingUser(null);
    //that one persists in session storage so we want to clear it just to be safe
    window.location = SIGNOUT;
  },[service])

  const trySignInRedirect = useCallback(() => {
    if (!userLoaded) {
      throw new Error("attempted to sign in redirect before site finished loading the user");
    }
    try {
      const redirectRoute = window.localStorage.getItem(REDIRECT_KEY);
      if (redirectRoute) {
        window.localStorage.removeItem(REDIRECT_KEY);
        navigate(redirectRoute);
      } else {
        navigate(DASHBOARD)
      }
    } catch (e) {
      navigate(DASHBOARD);
    }
  },[userLoaded, navigate])

  useEffect(() => {
    let studentId = parseInt(user?.studentId);
    if (isNaN(studentId)) {
      studentId = -1;
    }
    if (user && stringHasValue(user.name) && studentId  <= 0) {
      navigate("/newStudent");
    }

  },[user, navigate])

  const noticeKey = "ageNoticeDismissed";
  const storageDismissed = window.sessionStorage.getItem(noticeKey) === "true";
  const [dismissedAgeNotice, setDismissedAgeNotice] = useState(storageDismissed);
  const onDismissAgeNotice = useCallback(() => {
    window.sessionStorage.setItem(noticeKey,"true");
    setDismissedAgeNotice(true);
  }
  ,[]);

  const onPathwayCancel = useCallback(() => {
    setPathwayPrompted(false);
    window.sessionStorage.setItem(pathwayPromptKey,true)
    //trySignInRedirect();
  },[]);

  const onPathwayAccept = useCallback(() => {
    window.location = "https://www.byupathway.org/";
  },[])

  const {
    status:impersonateStatus,
    setSavingStatus: checkingImpersonation,
    setErrorStatus: impersonateError,
    setResetStatus: clearImpersonateStatus,
  } = useStatus()
  const [inAskMode, setInAskMode] = useState(false);
  const [userNameToImpersonate, setUserNameToImpersonate] = useState();
  const [impersonatingUser, setImpersonatingUser] = useState(getImpersonatingUser);

  const onImpersonate = useCallback(async () => {
    try {
      checkingImpersonation();
      const userToImpersonate = await service.checkUser(userNameToImpersonate);
      setImpersonatingUser(userToImpersonate);
      if (userToImpersonate?.studentId > 0) {
        service.setImpersonatingUser(userToImpersonate, true);
        setInAskMode(false);
        //hard location makes the photos work better without having to jump through hoops.
        window.location = "/student/dashboard";
      } else {
        service.setImpersonatingUser(null,true);
      }
      clearImpersonateStatus();
    } catch (e) {
      impersonateError(e);
      service.setImpersonatingUser(null,true);
    }
  },[
    userNameToImpersonate,
    checkingImpersonation,
    clearImpersonateStatus,
    impersonateError,
    service]);

  const onUserNameChange = useCallback((e) => {
    setUserNameToImpersonate(e.target.value);
  },[]);
  const onClearImpersonate = useCallback(() => {
    service.setImpersonatingUser(undefined,true);
    setImpersonatingUser(undefined);
    navigate("/");
  },[service, navigate]);
  const onChangeUser = useCallback(() => {
    service.setImpersonatingUser();
    setImpersonatingUser(undefined,false);
    setInAskMode(true);
  },[service]);

  const cancelImpersonateAsk = useCallback(() => {
    setInAskMode(false);
    setImpersonatingUser(undefined);
  },[]);

  const activeUser = impersonatingUser || user;

  return {
    user,
    impersonatingUser,
    name: activeUser?.name,
    userNameToImpersonate,
    inAskMode,
    onUserNameChange,
    onImpersonate,
    impersonateStatus,
    clearImpersonateStatus,
    onClearImpersonate,
    onChangeUser,
    cancelImpersonateAsk,

    ageNotice: activeUser?.ageNotice === true,
    piiRestricted: activeUser?.isEditPIRestricted === true,
    dismissedAgeNotice,
    onDismissAgeNotice,
    userLoaded,
    signOut,
    requireLoginForRoute,
    trySignInRedirect,
    signInFromMenuLink,
    pathwayPrompted,
    onPathwayAccept,
    onPathwayCancel,
    reloadAfterStudentCreated
  }
}

export default useSignedOnUser;
