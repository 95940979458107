import React, { useContext, useRef } from "react";

import { AppearanceSection } from "@churchofjesuschrist/eden-appearance";
import { A11y } from "@churchofjesuschrist/eden-buttons";
import Callout from "@churchofjesuschrist/eden-callout";
import {
  white,
  spacing24,
  spacing16,
  spacing8
} from "@churchofjesuschrist/eden-style-constants";
import styled from "styled-components";

import useSwitch from "../../generic/hooks/use-switch/use-switch";
import menuContext from "../top-bar/menu-context";

const Button = styled(A11y)`
flex:1;
`;

/*callout doesn't do well with positioning if you give your contents
a width us dictating the width. Hopefully they get an update as I would like
to put in this width definition myself for a better layout*/
const MenuCallout = styled(Callout)`
${({ isMobile, mobileWidth }) => isMobile && mobileWidth ? `max-width: ${mobileWidth}` : ""};
${({ isMobile, desktopWidth }) => !isMobile && desktopWidth ? `max-width: ${desktopWidth}` : ""};
`
const DropDownMenuContainer = styled.div`
padding:${({ isMobile }) => isMobile ? spacing8 : spacing16};
${({ isMobile, mobileWidth }) => isMobile && mobileWidth ? `width: ${mobileWidth}` : ""};
${({ isMobile, desktopWidth }) => !isMobile && desktopWidth ? `width: ${desktopWidth}` : ""};
`

const DropDownMenu = ({ Icon, children, mobileWidth, desktopWidth }) => {
  const { isMobile } = useContext(menuContext);
  const [menuOpen, openMenu, closeMenu] = useSwitch();
  const menuRef = useRef();

  const mappedChildren = React.Children.map(children, child => {
    return React.cloneElement(child, { close: closeMenu });
  });

  return (
    <>
      <Button data-id="drop-down-menu" ref={menuRef}
        onClick={openMenu}>
        <Icon color={white} size={spacing24}/>
      </Button>
      {/*TODO: we would like to get rid of this. Eden has a bug about this deal
      picking up OS dark mode settings sometimes. We can take out this appearance section
      at some point when that bug fix goes out */}
      <AppearanceSection mode="light">
        <MenuCallout
          forRef={menuRef}
          open={menuOpen}
          onClickOutside={closeMenu}
          isMobile={isMobile}
          mobileWidth={mobileWidth}
          desktopWidth={desktopWidth}
        >
          <DropDownMenuContainer
            isMobile={isMobile}
            mobileWidth={mobileWidth}
            desktopWidth={desktopWidth}
          >
            {mappedChildren}
          </DropDownMenuContainer>
        </MenuCallout>
      </AppearanceSection>
    </>
  );
}

export default DropDownMenu;