import React from "react";


const defaultFunc = () => {};
const signedOnUserContext = React.createContext({
  user:undefined,
  signOut:defaultFunc,
  requireLoginForRoute:defaultFunc,
  signInFromMenuLink:defaultFunc
});

export default signedOnUserContext;