import { putWrapper } from "../../utils/fetchWrapper";

export class preferenceService {
  constructor(overrideHost) {
    this.useHost = "";
    if (overrideHost) {
      this.useHost = overrideHost;
    };
  }

  updateUserPreference = async function (data) {

    const path = `${this.useHost}/api/v1/currentUser/UserPreference/`;

    const converted = await putWrapper({
      url: path,
      data
    });
    return converted;
  }
}

export default preferenceService;