import React from "react";

const defaultObj = {};
const defaultFunc = () => {};
const masterTranslationsContext = React.createContext({
  status:defaultObj,
  strings:defaultObj,
  language:"eng",//code3 from the language object,
  language2:"en",//the two character language code from the language object
  changeLanguage:defaultFunc,
  requestLanguageGroups:defaultFunc,
});

export default masterTranslationsContext;