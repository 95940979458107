import React, {useContext, useEffect} from "react";

import { spacing16 } from "@churchofjesuschrist/eden-style-constants";
import styled from "styled-components";

import LoadingSpinner from "../../generic/loading-spinner/loading-spinner";
import signedOnUserContext from "../../global-layout/master-layout/signed-on-user-context";

const SpinnerHolder = styled.div`
padding:${spacing16};
`

const SecuredContent = ({children}) => {

  const {
    requireLoginForRoute,
    user,
    userLoaded
  } = useContext(signedOnUserContext);

  useEffect(() => {
    if (userLoaded) {
      requireLoginForRoute(window.location.pathname);
    }
  },[userLoaded,requireLoginForRoute]);
  const showSpinner = !userLoaded || !user;

  return showSpinner ? (<SpinnerHolder><LoadingSpinner/></SpinnerHolder>) : children;

}

export default SecuredContent;
