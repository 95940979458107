import { useEffect, useState, useCallback } from "react";

export const initialStatus = { saving: false, error: false, success: false, warn:false };
export const savingStatus = { saving: true, error: false, success: false, warn:false };
export const successStatus = { saving: false, error: false, success: true, warn:false };
export const warnStatus = {saving:false, error:false, success:false, warn:true}

const useStatus = (initialValue = initialStatus, clearOnSuccess = true) => {
  const [status, setStatus] = useState(initialValue);

  const setResetStatus = useCallback(() => setStatus(initialStatus), []);
  const setSavingStatus = useCallback((data) => setStatus({...savingStatus, data}), []);
  const setSuccessStatus = useCallback(() => setStatus(successStatus), []);
  const setWarnStatus = useCallback(() => setStatus(warnStatus),[]);
  const setErrorStatus = useCallback(
    (error) => setStatus({ saving: false, error, success: false }),
    []
  );

  useEffect(() => {
    if (status.success && clearOnSuccess) {
      const timeout = setTimeout(() => {
        setStatus((oldStatus) => ({ ...oldStatus, success: false }));
      }, 2000);
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
  }, [status, clearOnSuccess]);

  return {
    status,
    setSavingStatus,
    setErrorStatus,
    setSuccessStatus,
    setResetStatus,
    setWarnStatus,
  };
};
export default useStatus;
