import {useMemo, useState, useCallback } from "react";

import { useMediaQuery } from "@churchofjesuschrist/eden-media-query";
import { useNavigate } from "react-router-dom";

import { initialMenuTitleObj } from "./menu-context";

const useMenu = () => {
  const navigate = useNavigate();

  const [hubOrProgram, setHubOrProgram] = useState();
  const [isNotMobile] = useMediaQuery(() => { }, "(min-width:840px)");
  const isMobile = useMemo(() => !isNotMobile, [isNotMobile]);
  const [menuTitleObj, setMenuTitleObj] = useState(initialMenuTitleObj);

  const menuTitleClick = useCallback((e) => {
    setMenuTitleObj(cur => {
      const {href} = cur;
      //in most places we don't really have an opinion for what the
      //href link on the submenu component should do. So it is just set to "#"
      //and we basically want that to be a no-op. As we get decision/definition
      //we just change the menuTitleHref property set in the usePageStandardFunctionality call
      //for a given page.
      if (href !== "#") {
        navigate(href);
      }
      e.preventDefault();
      //no-op. This is in a state setting hook because making this click depend on
      //menuTitleObj introduces a use-effect loop on every page if we don't do this.
      return cur;
    })
  },[navigate]);
  const menuTitleSetter = useCallback((newTitleObj) => {
    setMenuTitleObj({...newTitleObj, onClick:menuTitleClick});
  },[menuTitleClick]);

  return {
    isMobile,
    menuTitleObj,
    setMenuTitleObj:menuTitleSetter,
    hubOrProgram,
    setHubOrProgram
  }
}

export default useMenu;
