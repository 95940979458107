import React from "react";

import Modal from "@churchofjesuschrist/eden-platform-modal";
import styled from "styled-components";

const StyledModal = styled(Modal)`
    header{
        display:none;
    }
`;

const HeaderlessModal = ({children, ...props}) => <StyledModal
  open={props.active} {...props}>{children}</StyledModal>

export default HeaderlessModal;
