import languages from "./app-languages";
import tokensFromCookie from "./tokens-from-cookie";

const cookieKey = "lds-preferred-lang";
const determineLanguage = (user, code2 = false) => {
  const data = tokensFromCookie([cookieKey]);
  let lang = data[cookieKey];
  let match = languages.find((l) => l.code === lang);
  if (match) {
    return match.code;
  }

  if (user?.languageId) {
    const userMatch = languages.find((l) => l.id === user.languageId);
    if (userMatch) {
      return userMatch.code3;
    }
  }

  lang = navigator.language?.split('-')?.[0];
  match = languages.find((l) => l.code === lang);
  return match?.code || "en";
};

export const setLanguage = (lang = "en") => {
  const match = languages.find((l) => l.code3 === lang || l.code === lang);
  if (match) {
    document.cookie = `${cookieKey}=${lang}`;
    return true;
  }
  return false;
}

export default determineLanguage;