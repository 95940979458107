import React, {useContext} from "react";

import { A11y } from "@churchofjesuschrist/eden-buttons";
import {blue25, spacing4, spacing8} from "@churchofjesuschrist/eden-style-constants";
import styled from "styled-components";

import appLanguages from "../../../utils/app-languages";
import masterLanguageContext from "../master-layout/master-translations-context";
import menuContext from "../top-bar/menu-context";

const Button = styled(A11y)`
color:${blue25};
${({active}) => active ? "font-weight:bold;" : "" };
display:block;
margin-bottom:${spacing8};
margin-top:${spacing4};
`;

const Container = styled.div`
column-count:${({isMobile}) => isMobile ? 2 : 3};
`;


const defaultFunc = () => {};
const LanguagesMenu = ({close = defaultFunc}) => {
  const {changeLanguage = defaultFunc, language:code3} = useContext(masterLanguageContext);
  const {isMobile} = useContext(menuContext) ?? {};



  return <Container isMobile={isMobile} data-id="language-menu">
    {appLanguages.map(l => <Button key={l.code3} active={l.code3 === code3}
      onClick={() => {
        changeLanguage(l.code);
        close();
      }}>
      {l.name}
    </Button>)}
  </Container>
}

export default LanguagesMenu;