import {useEffect} from "react";

import { useLocation } from 'react-router-dom';


const scrollToTopOfScreenOrToIdFromUrl = () => {

  let url = window.location.href.split("#");
  let target = url[url.length - 1].toLowerCase();
  let element = document.getElementById(target);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start"});
  } else {
    //todo: scroll to the top
    window.scrollTo(0,0);
  }
}

const useMasterPageEvents = () => {
  const location = useLocation();
  //this use effect captures global navigation but this fires too early to pick up
  //child # spots. This part will move around the existing page via the new to institute menu
  //once it has rendered. It will not take care of the first time navigate to welcome
  //because the child dom elements don't exist at the time this use effect runs.
  //we need to have a use effect on the page (welcome page in this case)
  //that calls that helper when we get a load still.
  useEffect(() => {
    scrollToTopOfScreenOrToIdFromUrl()
  },[location])
}

export default useMasterPageEvents;
export {scrollToTopOfScreenOrToIdFromUrl};