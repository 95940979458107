import React, {useContext, useMemo} from "react";

import MapsApiContext from "./maps-api-context";
import masterTranslationsContext from "../master-layout/master-translations-context";

const MapsApiWrapper = ({children}) => {
  const {
    language = "en"
  } = useContext(masterTranslationsContext) ?? {};

  const production = useMemo(() => {
    const {hostname = ""} = window.location ?? {}
    return hostname.indexOf("myinstitute.churchofjesuschrist.org") >= 0
    || hostname.indexOf("institute.churchofjesuschrist.org") >= 0
    || hostname.indexOf("institutefrontdoor.churchofjesuschrist.org") >= 0;
  },[])

  /*
  //one example has, but maps team says that shouldn't be needed
  libraries:[
        'geometry'
      ]
      */
  const mapsApiObject = useMemo(() => {
    return {
      client: "InstituteClassFinder",
      ip: true,
      language,
      production,
      proxy:false,
    }
  },[language, production])

  return (<MapsApiContext.Provider value={mapsApiObject}>
    {children}
  </MapsApiContext.Provider>)

}

export default MapsApiWrapper;
