import React from "react";

import { LoadingSpinner as Icon } from "@churchofjesuschrist/eden-icons";
import {
  spacing8 as textMargin,
  spacing48,
  spacing64,
} from "@churchofjesuschrist/eden-style-constants";
import styled from "styled-components";

import useTranslations from "../../../utils/use-translations";

const LargeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${spacing64} ${spacing48};
`;
const SmallContainer = styled.div`
  display: flex;
`;
const SmallTextHolder = styled.div`
  position: relative;
  top: -3px;
  margin-left: ${textMargin};
`;

const stringGroups = [{
  name:"shared",
  keys:["loading"]
}]

const LoadingSpinner = ({ large = false, ...others }) => {
  const {managedStrings:useStrings} = useTranslations(stringGroups);
  //in one context where we use this component, we won't necessarily have our text
  //loaded so we would rather not show it in that case.
  const showText = useStrings.loading !== "!loading!";
  return large ? (
    <LargeContainer {...others}>
      <div>
        <Icon size={spacing64} />
      </div>
      {showText ? (<div>{useStrings.loading}...</div>) : null}
    </LargeContainer>
  ) : (
    <SmallContainer {...others}>
      <Icon /> {showText ? (<SmallTextHolder>{useStrings.loading}</SmallTextHolder>) : null}
    </SmallContainer>
  );
};



export default LoadingSpinner;

