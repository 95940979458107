import React, {useContext, useState, useEffect, useCallback, useMemo} from "react";

import Appearance from "@churchofjesuschrist/eden-appearance";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import AnalyticsAppContext from "./analytics-app-context.js";
import MasterTranslationsContext from "./master-translations-context";
import SignedOnUserContext from "./signed-on-user-context";
import useAnalyticsScript from "./use-analytics-script.js";
import useMasterPageEvents from "./use-master-page-events";
import useMasterTranslations from "./use-master-translations";
import useSignedOnUser from "./use-signed-on-user";
import StringsContext from "../../../utils/strings/strings-context";
import useTranslation from "../../../utils/use-translations";
import ConfirmModal from "../../generic/modals/confirm-modal/confirm-modal";
import IosInstallModal from "../ios-install-modal/ios-install-modal";
import MainMenu from "../main-menu/main-menu";
import MapsApiWrapper from "../maps-api-wrapper/maps-api-wrapper";
import PwaBanner from "../pwa-banner/pwa-banner";
import MenuContext from "../top-bar/menu-context";
import TopBar from "../top-bar/top-bar";
import useMenu from "../top-bar/use-menu";

const PwaInstallBanner = styled(PwaBanner)`
  position: fixed;
  bottom: 0;
  left: 0;
`;

const stringGroups = [
  {
    name:"shared",
    keys:[
      "pathwayQuestion",
      "pathwayOk",
      "pathwayCancel"
    ]
  }
]

const PathwayPrompt = () => {
  const {
    pathwayPrompted,
    onPathwayAccept,
    onPathwayCancel
  }  = useContext(SignedOnUserContext);
  const {managedStrings} = useTranslation(stringGroups);
  return (<ConfirmModal active={pathwayPrompted}
    onConfirm={onPathwayAccept}
    onCancel={onPathwayCancel}
    message={managedStrings.pathwayQuestion}
    okText={managedStrings.pathwayOk}
    cancelText={managedStrings.pathwayCancel}
    mode="info"/>)
}

const MasterLayout = ({ children }) => {

  useMasterPageEvents();
  const menuContext = useMenu();
  const userContext = useSignedOnUser();
  const analyticsHook = useAnalyticsScript(userContext);
  const translationsContext = useMasterTranslations(userContext?.user);

  const [pwaDismissed, setPwaDismissed] = useState(false);
  const [showPwaBanner, setShowPwaBanner] = useState(false);
  const [iosPwa, setIosPwa] = useState(false);
  const [iosPwaModalActive, setIosPwaModalActive] = useState(false);
  const [installPrompt, setInstallPrompt] = useState(null);

  const dismissPwa = useCallback(() => {
    setPwaDismissed(true);
  }, []);

  const pwaInstallClicked = useCallback(() => {
    if (!iosPwa) {
      // hide our user interface that shows our install button
      setPwaDismissed(true);
      // Show the prompt
      if (installPrompt) {
        installPrompt.prompt();

        // Wait for the user to respond to the prompt
        installPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
          setInstallPrompt(cur => {
            return null;
          });
        });
      }
    }
    else {
      setIosPwaModalActive(true);
    }
  }, [installPrompt, iosPwa]);

  const closeIosModal = useCallback(() => {
    setIosPwaModalActive(false);
  }, []);

  useEffect(() => {
    const isIos = () => {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      ('standalone' in window.navigator) && (window.navigator.standalone);

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      //ios
      setShowPwaBanner(true);
      setIosPwa(true);
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setInstallPrompt(e);
      // Update UI to notify the user they can add to home screen
      //android
      setShowPwaBanner(true);
    });
  }, []);

  const location = useLocation();
  const isEmployeeExperience = useMemo(() => (location?.pathname?.indexOf("pgmadmin") ?? -1) > 0,
    [location?.pathname]) ;


  return (
    <Appearance mode="light">
      <SignedOnUserContext.Provider value={userContext}>
        <AnalyticsAppContext.Provider value={analyticsHook}>
          <MasterTranslationsContext.Provider value={translationsContext}>
            <MapsApiWrapper>
              <StringsContext.Provider value={translationsContext.strings}>
                <MenuContext.Provider value={menuContext}>
                  <TopBar />
                  <MainMenu isEmployeeExperience={isEmployeeExperience} />
                  <PathwayPrompt/>
                  {children}
                  {!pwaDismissed && showPwaBanner &&
                  <PwaInstallBanner
                    installCallback={pwaInstallClicked}
                    closeCallback={dismissPwa} />}
                  <IosInstallModal active={iosPwaModalActive} closeCallback={closeIosModal}/>
                </MenuContext.Provider>
              </StringsContext.Provider>
            </MapsApiWrapper>
          </MasterTranslationsContext.Provider>
        </AnalyticsAppContext.Provider>
      </SignedOnUserContext.Provider>
    </Appearance>

  );
}

export default MasterLayout;
