import React, { useMemo } from "react";

import {
  text120, grey40, fontSize18, fontSize32, spacing16,
} from "@churchofjesuschrist/eden-style-constants";
import styled from "styled-components";

import useTranslation from "../../../utils/use-translations";

const Container = styled.div`
  margin-top:${spacing16};
  color: ${grey40};
  text-align:center;
`

const ErrorCode = styled.div`
  font-size: ${fontSize18}; 
`;

const ErrorMessage = styled.div`
  font-size: ${fontSize32};
  color: ${text120};
  text-align:center;
`;


const stringGroups = [
  {
    name:"error",
    keys:[
      "errorCode",
      "wereSorry404",
      "checkInternetConnection",
      "serverProblem",
      "unauthorized",
      "pageProblem"]
  }
]
//noStrings is only set to true on the App component that is outside our strings contexts
//and only ever kicks up when we have something go wrong that may even mean strings failed to load.
//so we have a fallback strings object to use.
const noStringsObject = {
  pageProblem: "There was a problem on this page",
  errorCode:"code",
  wereSorry404:"404",
  checkInternetConnection:"interwebs",
  serverProblem:"server problem",
  unauthorized:"no permissions",
}

const ErrorComponent = ({ errorCode, noStrings = false }) => {
  const {managedStrings} = useTranslation(stringGroups);
  const strings = useMemo(() => noStrings ? noStringsObject : managedStrings,
    [managedStrings, noStrings])


  return <Container>
    {errorCode && <ErrorCode>{strings.errorCode}: {errorCode}</ErrorCode>}
    {errorCode === 404 &&
          <>
            <ErrorMessage>{strings.wereSorry404}</ErrorMessage>
            <div>{strings.checkInternetConnection}</div>
          </>}
    {(errorCode === 500) &&
      <ErrorMessage>{strings.serverProblem}</ErrorMessage>}
    {errorCode === 403 &&
        <ErrorMessage>{strings.unauthorized}</ErrorMessage>}
    {!errorCode &&
      <ErrorMessage>{strings.pageProblem}</ErrorMessage>}
  </Container>
}

export default ErrorComponent