import {
  isBefore,
  isAfter,
  isSameDay,
  differenceInCalendarWeeks,
} from "date-fns";

import pad from "./pad";
import valueIsNonEmptyString from "./value-is-non-empty-string";

const IsDate = (date) => {
  if (!date) return false;
  if (typeof date.getFullYear !== "function") {
    return false;
  }
  const year = date.getFullYear();
  return !isNaN(year);
};

//front door is giving dates without a timzone value. WISE puts a Z on the end of the times
//which injects timezone opinion and needs toBrowserPureDate.
//Without the Z there, we just want to take the date to a string.
const serverStringToBrowserDate = string => new Date(string);//toBrowserPureDate(new Date(string));

const uiDateToGmtIsoString = (date) => {
  if (!IsDate(date)) {
    return "";
  }
  const month = pad(date.getMonth() + 1, 2, "0");
  const day = pad(date.getDate(), 2, "0");
  return `${date.getFullYear()}-${month}-${day}T00:00:00Z`;
};

//this may be a problem as browser pure date isn't being run.
//we aren't using it anywhere as we never have date inputs to collect from users.
//expects a browser date from everything in the react app now!
const prepDateForSubmission = (date) => {
  if (!IsDate(date)) {
    return "";
  }
  return uiDateToGmtIsoString(date);
};

const defaultDateDisplayOptions = {
  day: "2-digit",
  month: "long",
  year: "numeric",

};

const DateToDisplayString = (date, options = defaultDateDisplayOptions) => {
  if (IsDate(date)) {
    return date.toLocaleDateString(
      undefined, // locale - using undefined lets the browser figure it out
      options
    );
  }
  return "";
};

const prepObjectDatePropsForSubmission = (obj) => {
  const newObj = { ...obj };
  for (let prop in newObj) {
    // eslint-disable-next-line no-prototype-builtins
    if (newObj.hasOwnProperty(prop)) {
      if (IsDate(newObj[prop])) {
        newObj[prop] = prepDateForSubmission(newObj[prop]);
      }
    }
  }
  return newObj;
};
const dateAsTimeTwentyFourToTwelve = (date) => {
  const options = {
    hour: "2-digit",
    minute: "numeric",
    hour12: true,
  };
  const timeString = date.toLocaleString("en-US", options);

  return timeString;
}
const twentyFourToTwelve = (time) => {
  const date = new Date("01/01/0000 " + time);
  return dateAsTimeTwentyFourToTwelve(date);
};

const isDateInRange = (dateToCheck, minDate, maxDate) => {
  if (!IsDate(dateToCheck)) return false;
  //if we don't have valid min and max dates we return true.
  if (!IsDate(minDate) || !IsDate(maxDate)) {
    return true;
  }
  const useMinDate = minDate;
  const useMaxDate = maxDate;
  const useDateToCheck = dateToCheck;

  const minPasses =
    isSameDay(useDateToCheck, useMinDate) ||
    isAfter(useDateToCheck, useMinDate);
  const maxPasses =
    isSameDay(useDateToCheck, useMaxDate) ||
    isBefore(useDateToCheck, useMaxDate);
  return minPasses && maxPasses;
};
const parseTimeStringToMinutes = (timeString) => {
  if (!valueIsNonEmptyString(timeString)) {
    return -1;
  }
  let [hours = -1, minutes = -1] = timeString.split(":");
  hours = parseInt(hours);
  minutes = parseInt(minutes);
  if (hours < 0 || minutes < 0) {
    return -1;
  }
  return hours * 60 + minutes;
};

const parseTimeObject = (timeObject) => {
  if (timeObject && timeObject.hours > 0 && timeObject.minutes >= 0) {
    const { hours, minutes } = timeObject;
    const minuteString = minutes < 10 ? `0${minutes}` : minutes;
    const hoursString = hours > 12 ? `${hours - 12}` : `${hours}`;
    const amPm = hours > 12 ? 'PM' : 'AM';
    return `${hoursString}:${minuteString} ${amPm}`;
  }
  return '';
}

const getWeekForDate = (date) => {
  const useDate = date;
  const year = useDate.getUTCFullYear();
  const jan1 = new Date(year, 0, 1);
  const week = differenceInCalendarWeeks(date, jan1) + 1;
  const result = `${year}_${pad(week, 2)}`;
  return result;
};

const convertDatesOnObject = (obj) => {
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "object" && value) {
      convertDatesOnObject(value);
    } else if (typeof value === "string" && /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value)) {
      // eslint-disable-next-line
      obj[key] = serverStringToBrowserDate(value);
    }
  }
  return obj;
};

function convertDatesOnArray(data) {
  data.forEach((item) => {
    convertDatesOnObject(item);
  });

  return data;
}

export {
  DateToDisplayString,
  getWeekForDate,
  parseTimeStringToMinutes,
  IsDate,
  prepObjectDatePropsForSubmission,
  twentyFourToTwelve,
  dateAsTimeTwentyFourToTwelve,
  prepDateForSubmission,
  isDateInRange,
  uiDateToGmtIsoString,
  serverStringToBrowserDate,
  parseTimeObject,
  convertDatesOnObject,
  convertDatesOnArray,
};
