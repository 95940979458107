import React from "react";

import { H2 } from "@churchofjesuschrist/eden-headings";
import HtmlFragment from "@churchofjesuschrist/eden-html-fragment";
import { LoadingSpinner } from "@churchofjesuschrist/eden-icons";
import { spacing24, textColorPrimary } from "@churchofjesuschrist/eden-style-constants";
import Modal from "@churchofjesuschrist/eden-tool-modal";
import styled from "styled-components";

import { DateToDisplayString, serverStringToBrowserDate } from "../../../utils/date-utils";
import useTranslation from "../../../utils/use-translations";
import Error from "../../generic/error/error";

const SizedModal = styled(Modal)`
color: ${textColorPrimary};
max-width:none !important;
`

const stringGroups = [
  {
    name:"shared",
    keys:["whatsNew"]
  }
];

const ReleaseNotesModal = ({ isOpen, onClose, releaseNotes, releaseNotesStatus }) => {
  const { managedStrings } = useTranslation(stringGroups);

  return (
    <SizedModal open={isOpen} onClose={onClose} header={managedStrings.whatsNew}>
      {releaseNotesStatus?.saving ? <LoadingSpinner size={spacing24} /> :
        releaseNotesStatus?.error && <Error />}
      {releaseNotes?.map((rn, index) => <div key={index}>
        <H2>{rn.title}</H2>
        <p>{DateToDisplayString(serverStringToBrowserDate(rn.date))}</p>
        <HtmlFragment content={rn.description} />
        {index !== releaseNotes.length - 1 && <hr />}
      </div>)}
    </SizedModal>
  );
};

export default ReleaseNotesModal;