import { useEffect, useState, useCallback} from "react";

import languages from "../../../utils/app-languages";
import determineLanguage, { setLanguage } from "../../../utils/cookie-language-helper";
import loadTranslations from "../../../utils/translation-loader-service/translation-loader-service";
import preferenceService from "../../app/preference-service";
import useStatus from "../../generic/hooks/use-status/use-status";

const prefService = new preferenceService();

const getLanguageData = (userData) => {
  const code = determineLanguage(userData) ?? "en";
  const langObj = languages.find(l => l.code === code || l.code3 === code);
  return {
    code,
    code3: langObj?.code3,
    code2: langObj?.code,
    groups: ["shared"]
  }
};

const useMasterTranslations = (user) => {
  const {
    status,
    setSavingStatus:loading,
    setErrorStatus:error,
    setResetStatus:clearStatus,
  } = useStatus({saving:true});
  // #region language state work
  const [languageData,setLanguageData] = useState(() => getLanguageData(user));
  const [strings,setStrings] = useState();

  useEffect(() => {
    setLanguageData(getLanguageData(user));
  }, [user]);

  useEffect(() => {
    const load = async () => {
      try {
        loading();
        const loadedStrings = await loadTranslations(languageData.code,languageData.groups);
        setStrings(loadedStrings);
        clearStatus();
      } catch (e) {
        error(e);
      }

    }
    load();
  },[clearStatus, error, languageData.code, languageData.groups, loading]);

  const changeLanguage = useCallback((lang) => {
    const updateValue = async () => {
      loading();
      try {
        const langObj = languages.find(l => l.code === lang);
        await prefService.updateUserPreference({
          key: 'languageId',
          value: langObj?.id || 0,
        });
        setLanguage(lang);
        setLanguageData(curData => {
          if (curData.code === lang) {
            return curData;//no-op
          }
          return {...curData, code:lang, code3:langObj?.code3};
        })
      } catch (e) {
        error();
      }
    }
    updateValue();
  },[error, loading]);

  const requestLanguageGroups = useCallback((groups = []) => {
    setLanguageData(currentLanguageData => {
      const {groups:currentGroups = []} = currentLanguageData ?? {};
      //find any of the newly requested groups that aren't already in our strings data
      const groupsToAdd = groups.filter(g => currentGroups.indexOf(g) < 0);
      if (groupsToAdd.length === 0) {
        return currentLanguageData;
      }
      return {
        ...currentLanguageData,
        groups:[...currentGroups, ...groupsToAdd]}
    })
  },[]);
    // #endregion

  return {
    status,
    strings,
    changeLanguage,
    language:languageData?.code3,
    language2:languageData?.code2,
    requestLanguageGroups,
  }
}

export default useMasterTranslations;