
import React, {useCallback, useContext, useMemo} from "react";

import SubNavigation from "@churchofjesuschrist/eden-sub-navigation";
import { useNavigate } from "react-router-dom";

import stringHasValue from "../../../utils/string-has-value";
import useTranslation from "../../../utils/use-translations";
import Impersonation from "../impersonation/impersonation";
import AnalyticsAppContext from "../master-layout/analytics-app-context";
import masterTranslationsContext from "../master-layout/master-translations-context";
import menuContext from "../top-bar/menu-context";


// TODO: Define href for each item and translations and confirm text
const itemsData = [
  {
    text_key: "explore",
    "data-id":"main_menu_explore",
    items: [
      {
        "data-id":"explore_find_classes",
        text_key: "findClassesMenu",
        route:"/findClasses"
      },
      {
        "data-id":"explore_find_online",
        text_key: "findOnlineInstitute2",
        route:"/findInstitute/online"
      },
      {
        "data-id":"explore_find_physical",
        text_key: "findPhysicalInstitute",
        route:"/findInstitute"
      },
      {
        "data-id":"explore_find_church_news",
        text_key: "churchNewsroom",
        route:"/newsRoom"
      },
      {
        "data-id":"explore_find_catalog",
        text_key: "catalog",
        route:"/courses"
      }
    ],
  },
  {
    "data-id":"main_menu_resources",
    text_key: "resources",
    items: [
      {
        "data-id":"resources_course_materials",
        text_key: "courseMaterials",
        href: 'https://www.churchofjesuschrist.org/si/institute/manuals',
      },
      {
        "data-id":"resources_graduation_requirements",
        text_key: "graduationRequirements",
        href: 'https://www.churchofjesuschrist.org/si/institute/about/institute-graduation',
      },
      {
        "data-id":"resources_ya",
        text_key: "yaWeekly",
        href: 'https://www.churchofjesuschrist.org/study/ya-weekly/welcome-to-ya-weekly/welcome-to-ya-weekly',
      },
      {
        "data-id":"resources_ces",
        text_key: "ces",
        href: 'https://www.churchofjesuschrist.org/church-education',
      }
    ],
  },
  {
    "data-id":"main_menu_new",
    text_key: "newToInstitute2",
    items: [
      {
        "data-id":"new_pathway",
        text_key: "pathwayStudent2",
        route:"/welcome#pathway"
      },
      {
        "data-id":"new_seminary",
        text_key: "semGraduate",
        route:"/welcome#seminary"
      },
      {
        "data-id":"new_missionary",
        text_key: "returnMissionary",
        route:"/welcome#missionary"
      },
      {
        "data-id":"new_friends",
        text_key: "friends",
        route:"/welcome#friends"
      }
    ],
  },
  {
    "data-id":"main_menu_about",
    text_key: "about",
    items: [
      {
        "data-id":"about_purpose",
        text_key: "institutePurpose",
        href: 'https://www.churchofjesuschrist.org/si/institute/about/the-purpose-of-institute',
      },
      {
        "data-id":"about_objectives",
        text_key: "objectives",
        href: 'https://www.churchofjesuschrist.org/si/objective',
      },
      {
        "data-id":"about_institute_types",
        text_key:"instituteTypes",
        route:"/instituteTypes"
      },
      {
        "data-id":"about_faq",
        text_key: "faq",
        route:"/faq",
      },
      {
        "data-id":"about_history",
        text_key: "history",
        href: 'https://www.churchofjesuschrist.org/si/history',
      },
      {
        "data-id":"about_support",
        text_key: "support",
        route:"/support"
      }
    ],
  },
];

const stringGroups = [
  {
    name:"shared",
    keys:[
      "explore",
      "findClassesMenu",
      "findOnlineInstitute2",//want to avoid a name collision on flattened strings
      //with our home page strings. Use a 2 on the name to be extra explicit
      "findPhysicalInstitute",
      "events",
      "catalog",
      "churchNewsroom",
      "resources",
      "courseMaterials",
      "graduationRequirements",
      "yaWeekly",
      "ces",
      "newToInstitute2",//2 to avoid home page name collision when strings flatten
      "pathwayStudent2",
      "semGraduate",
      "returnMissionary",
      "friends",
      "institutePurpose",

      "about",
      "instituePurpose",
      "instituteTypes",
      "objectives",
      "faq",
      "history",
      "support",
      "classes",
      "teachers",
      "events",
      "visitUs",
      "contactUs",
      "institutes",
      "news"



    ],
  }
]


const defaultFunc = () => {};
const MainMenu = ({onClick, isEmployeeExperience = false}) => {
  const {analyticsClickEvent = defaultFunc} = useContext(AnalyticsAppContext) ?? {};
  const {menuTitleObj, hubOrProgram} = useContext(menuContext) ?? {};
  const {language = "eng"} = useContext(masterTranslationsContext) ?? {};
  const {managedStrings} = useTranslation(stringGroups);
  const navigate = useNavigate();

  const processItem = useCallback((item) => {
    const newItem = {...item};
    if (newItem.text_key) {
      newItem.text = managedStrings[newItem.text_key] ?? "";//unit tests need some value
      //but unit tests don't have a strings context set up
    }
    if (newItem.href) {
      newItem.href = `${newItem.href}?lang=${language}`;
    }
    if (newItem.route) {
      newItem.href = newItem.route;
      newItem.onClick = (e) => {
        analyticsClickEvent(`menu-click-${item.text_key}`);
        const hasClickFunc = typeof onClick === "function";
        if (newItem.route || hasClickFunc) {
          e.preventDefault();
          if (hasClickFunc) {
            onClick(newItem);
          } else {
            navigate(item.route);
          }
        }
      }
    } else {
      newItem.onClick = () => {
        analyticsClickEvent(`menu-click-${item.text_key}`);
        if (typeof onClick === "function") {
          onClick(newItem);
        }
      }
    }
    if (Array.isArray(newItem.items)) {
      newItem.items = newItem.items.map(processItem);
    }
    return newItem;

  },[managedStrings, navigate, language, onClick, analyticsClickEvent]);
  const itemFromHubOrProgram = useCallback((hubOrProgram) => {
    //this is set up to never call with a falsy hubOrProgramObject;
    const {hubName,
      programName,
      programs = [],
      id,
      parentHubId,
      parentHubName,
      isOnline = false} = hubOrProgram;

    const isHub = stringHasValue(hubName);
    const baseUrl = isHub ? `/hub/${id}` : `/institute/${id}`;

    const lastSubs = isHub ? programs.map(p => {
      return processItem({
        "data-id":`program_${p.id}`,
        text:p.programName,
        route:`/institute/${p.id}`
      })
    }) : !isOnline ? [processItem({
      "data-id":"visit_us",
      text_key:"visitUs",
      route: `${baseUrl}/visitUs`
    })] : [processItem({
      "data-id": "contact_us",
      text_key: "contactUs",
      route: `${baseUrl}/contactUs`
    })];
    if (!isHub && stringHasValue(parentHubName)) {
      lastSubs.push(processItem({
        "data-id":"parent_hub",
        text:parentHubName,
        route:`/hub/${parentHubId}`
      }));
    }
    const item = {
      text: isHub ? hubName : programName,
      "data-id":"main_menu_hubOrProgram",
      items:[
        processItem({
          "data-id":"classes",
          text_key:"classes",
          route: `${baseUrl}/classes`
        }),
        processItem({
          "data-id":"news",
          text_key:"news",
          route: `${baseUrl}/events`
        }),
        processItem({
          "data-id":"teachers",
          text_key:"teachers",
          route: `${baseUrl}/teachers`
        }),
        ...lastSubs
      ]
    };
    return item;
  },[processItem]);

  const items = useMemo(() => {
    const processed = itemsData.map(processItem);
    if (isNaN(hubOrProgram?.id)) {
      return processed;
    }
    return [itemFromHubOrProgram(hubOrProgram), ...processed];
  }, [processItem, hubOrProgram, itemFromHubOrProgram]);

  return (
    <>
      {!isEmployeeExperience && <div>
        <SubNavigation
          title={menuTitleObj}
          items={items}
        />
      </div>}
      <Impersonation/>
    </>
  );
}

export default MainMenu;
