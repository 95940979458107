import React, {useContext} from "react";

import {Info, Warn, Error} from "@churchofjesuschrist/eden-banner";
import {Primary, Secondary} from "@churchofjesuschrist/eden-buttons";
import {Input} from "@churchofjesuschrist/eden-form-parts";
import { LoadingSpinner } from "@churchofjesuschrist/eden-icons";
import {spacing8, spacing4} from "@churchofjesuschrist/eden-style-constants";
import styled from "styled-components";

import { highlightAmber } from "../../../utils/deprecated-colors";
import stringHasValue from "../../../utils/string-has-value";
import useTranslation from "../../../utils/use-translations";
import signedOnUserContext from "../master-layout/signed-on-user-context";

const Container = styled.div`
width:100%;
padding:${spacing4};
display:flex;
justify-content:center;
background-color:${highlightAmber};
align-items:center;
div, button, input{
    margin-right:${spacing8};
}
`;

const stringGroups = [
  {
    name:"shared",
    keys:[
      "impersonating",
      "impersonate",
      "stopImpersonating",
      "impersonatingEditMode",
      "impersonatingReadOnly",
      "changeUser",
      "userName",
      "userNotValid",
      "errorImpersonating",
      "cancel"
    ]
  }
]

const MyInput = styled(Input)`
width:220px;`;

const defaultObj = {};
const Impersonation = () => {
  const {
    user = defaultObj,
    impersonatingUser,
    userNameToImpersonate,
    inAskMode,
    cancelImpersonateAsk,
    onUserNameChange,
    onImpersonate,
    impersonateStatus,
    clearImpersonateStatus,
    onClearImpersonate,
    onChangeUser
  } = useContext(signedOnUserContext) ?? defaultObj;

  const {
    canAdmin = false
  } = user;
  const {managedStrings} = useTranslation(stringGroups);

  if (inAskMode || impersonatingUser?.studentId <= 0 || impersonateStatus?.error) {
    return (<><Container>
      <MyInput small value={userNameToImpersonate}
        onChange={onUserNameChange}
        data-id="impersonation-username"/>
      <Primary small disabled={impersonateStatus?.saving || !stringHasValue(userNameToImpersonate) }
        onClick={onImpersonate}
        data-id="impersonation-start">
        {managedStrings.impersonate}
        {impersonateStatus.saving && <LoadingSpinner/>}
      </Primary>
      <Secondary small
        onClick={cancelImpersonateAsk}
        data-id="impersonation-cancel">
        {managedStrings.cancel}
      </Secondary>
    </Container>
    {impersonateStatus.error &&
      <Error data-id="impersonation-error"
        description={managedStrings.errorImpersonating} onClose={clearImpersonateStatus}/>}
    {impersonatingUser?.studentId <= 0 &&
      <Error data-id="impersonation-invalid-user"
        description={managedStrings.userNotValid}/>}
    </>)
  }
  if (impersonatingUser) {

    return (<><Container>
      <div>{managedStrings.impersonating} :
        <span data-id="impersonation-result">{impersonatingUser.impersonatedUserName}</span></div>
      <Secondary small onClick={onChangeUser} data-id="impersonation-change-user">
        {managedStrings.changeUser}
      </Secondary>
      <Primary small onClick={onClearImpersonate}
        data-id="impersonation-end">
        {managedStrings.stopImpersonating}
      </Primary>
    </Container>
    {canAdmin ? <Warn data-id="impersonation-warn"
      description={managedStrings.impersonatingEditMode}/> :
      <Info data-id="impersonation-notice"
        description={managedStrings.impersonatingReadOnly}/>}
    </>)
  }

  return null;//nothing to do.

}


export default Impersonation;