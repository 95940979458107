import {useState, useEffect, useCallback} from "react";

const defaultFunc = () => {};
const useInterval = ({milliseconds = 10000,
  autoStart = true,
  onTrigger = defaultFunc}) => {
  const [intervalId, setIntervalId] = useState(-1);

  const restart = useCallback(() => {
    setIntervalId(curId => {
      if (curId > 0) {
        clearInterval(curId);
      }
      const id = setInterval(onTrigger, milliseconds);
      return id;
    })
  }, [milliseconds, onTrigger]);
  const stop = useCallback(() => {
    setIntervalId(curId => {
      if (curId > 0) {
        clearInterval(curId);
        return -1;
      }
      return curId;
    })
  },[]);

  useEffect(() => {
    if (autoStart) {
      restart();
    }
  },[restart, autoStart]);

  useEffect(() => {
    return () => {
      if (intervalId > 0) {
        clearInterval(intervalId);
      }
    }
  },[autoStart, restart, intervalId]);

  return {
    restart,
    stop
  };

}

export default useInterval;