import {Children} from "react";

import useFeatureFlag from "../hooks/use-feature-flag/use-feature-flag";

const FeatureFlags = ({flag, global = false, service, children}) => {
  const {
    status,
    clearStatus,
    loadedFlag
  } = useFeatureFlag(flag, global, service);

  const [flagOn, flagOff, loading, error] = Children.toArray(children);

  if (status.saving && loading) {
    return loading;
  }
  if (status.error && Error) {
    //error.props.clear = clearStatus;
    const useError = {...error,
      props: {...error.props, clear:clearStatus}
    }
    return useError;
  }
  if (status.success && !loadedFlag && flagOff) {
    return flagOff;
  }
  if (status.success && loadedFlag && flagOn) {
    return flagOn;
  }
  return null;
}

export default FeatureFlags;