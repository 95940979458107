import {useEffect, useCallback, useState} from "react";

import determineLanguage from "../../../utils/cookie-language-helper";
import useInterval from "../../generic/hooks/use-interval/use-interval";

window.digitalData = window.digitalData || [];
window.digitalDataEvents = {
  pageView: "Page View",
  productView: "Product View",
  addToCart: "Add to Cart",
  removeFromCart: "Remove from Cart",
  checkout: "Checkout",
  purchase: "Purchase",
  search: "Search",
  component: {
    click: "Component Click",
    share: "Share",
    formStart: "Form Start",
    formError: "Form Error",
    formSubmit: "Form Submit",
    download: "Download",
    checklistUpdated: "Checklist Updated"
  },
  authenticate: "Authenticate"
};

const useAnalyticsScript = (signedInUserInfo) => {

  const [loaded, setLoaded] = useState(false);
  const [eventObject, setEventObject] = useState();

  const {user, userLoaded} = signedInUserInfo;

  const loadCheckTrigger = useCallback(() => {
    //window._satellite is a global variable their analytics sets up when their script runs.
    if (window._satellite && userLoaded) {
      setLoaded(true);
    }
  },[userLoaded]);

  const {stop:stopLoadCheck} = useInterval({
    milliseconds:100,
    onTrigger: loadCheckTrigger,
    autoStart:true});

  useEffect(() => {
    if (loaded) {
      stopLoadCheck();
    }
  },[loaded, stopLoadCheck]);

  //note: the analytics script is injected via the header-footer script.
  //the lane for the analytics is determined by the header-footer script.
  //look to index.html in the public directory to see that we decide prod vs non-prod based on window.location.hostname

  const analyticsPageEvent = useCallback((pageName, parameters) => {

    let useParameters = parameters;
    if (parameters && parameters["*"]) {
      const {"*":asterisk123456, ...others} = parameters;
      useParameters = others;
    }
    const language = determineLanguage(user, true);//three character language code needed

    setEventObject({
      event:window.digitalDataEvents.pageView,
      page: {
        info: {
          name: pageName, //String - friendly name for the current page, usually based on the URL
          crumbs: useParameters,
          language,
          siteName: "myInstitute2"   // String - friendly unique name that identifies the current site, this will be provided in a Jira ticket
        }
      }
    })
  },[user])



  const analyticsClickEvent = useCallback((clickName) => {
    setEventObject({
      event:window.digitalDataEvents.component.click,
      component: {
        category: {
          primary: clickName
        }
      }})

  },[]);

  useEffect(() => {
    if (loaded && eventObject) {
      window.digitalData.push(eventObject);
    }
  },[loaded,eventObject]);

  return {
    analyticsPageEvent,
    analyticsClickEvent
  }
}

export default useAnalyticsScript;