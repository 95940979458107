import { useContext, useMemo, useCallback } from "react";

import manageStrings from "./manage-strings";
import StringsContext from "./strings/strings-context";

const useTranslation = (stringGroups, debugg = false) => {
  const strings = useContext(StringsContext);


  const managedStrings = useMemo(() => {
    //const {verboseMissingStrings = false} = configuration ?? {};
    //if verboseMissingStrings is true, you get !key! instead of...
    //
    const verboseMissingStrings = false;
    return manageStrings(strings, stringGroups, verboseMissingStrings)
  },  [
    strings,
    stringGroups,
  ]);
  /*when a string hasn't loaded it can resolve to "..." which can break eden tabs. You can have multiple tabs
  with "..." as their label, and eden uses that label as a key. So the delay to load translations can cause tabs to
  shrink down to one single tab, and it doesn't update if those change later in the life of the page. */
  const tabSafeLookup = useCallback((key) => {
    const fallback = `!${key}!`;
    try {
      const val = managedStrings[key];
      if (!val || val === "...") {
        return fallback;
      }
      return val;
    } catch (e) {
      return fallback;
    }

  },[managedStrings])

  return { strings, managedStrings, tabSafeLookup };
}

export default useTranslation;