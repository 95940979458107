import { createContext } from "react";

export const initialMenuTitleObj = {
  text: ' ',
};

const MenuContext = createContext({
  isMobile: false,
  menuTitleObj: initialMenuTitleObj,
  setMenuTitleObj: () => { },
});

export default MenuContext;
