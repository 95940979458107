import React, { useCallback } from "react";

import {
  spacing16
} from "@churchofjesuschrist/eden-style-constants";
import ToolModal from "@churchofjesuschrist/eden-tool-modal";
import styled from "styled-components";

import useTranslation from "../../../utils/use-translations";
import { IosShare, IosAddToHome } from "../../generic/icons/svg-icons/svg-icons"

const IosModal = styled(ToolModal)`
    overflow: hidden;
`;

const PwaMessage = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 ${spacing16};
  flex-wrap: wrap;
  margin: 0 auto;
  width: 292px !important;
  min-width: 0;
`;

const ShareIcon = styled(IosShare)`
    align-self: center;
    margin: 0 5px;
`;

const AddToHomeIcon = styled(IosAddToHome)`
    align-self: center;
    margin: 0 0 0 5px;
`;

const stringGroups = [
  {
    name:"shared",
    keys:[
      "iosInstallMessage"]
  }
];

const parseIosInstallMessageString = (string) => {
  const txt = string.iosInstallMessage;
  if (!txt || txt.indexOf("{0}") < 0) {
    return "Invalid share icon";
  }
  if (!txt || txt.indexOf("{1}") < 0) {
    return "Invalid add to homescreen icon";
  }
  const firstSplit = txt.split("{0}");
  const secondSplit = firstSplit[1].split("{1}");
  return <PwaMessage>
    <div>
      {firstSplit[0]}
      <ShareIcon />
    </div>
    <div>
      {secondSplit[0]}
      <AddToHomeIcon />
      {secondSplit[1]}
    </div>
  </PwaMessage>
};

const IosInstallModal = ({active, closeCallback = () => {}, ...others}) => {
  const {managedStrings} = useTranslation(stringGroups);

  const closeClick = useCallback(() => {
    closeCallback();
  }, [closeCallback])

  return (
    <IosModal open={active} onClose={closeClick} {...others}>
      {parseIosInstallMessageString(managedStrings)}
    </IosModal>
  );
};

export default IosInstallModal;