import fetchWrapper from "../../../../utils/fetchWrapper";

const featureFlagService = () => {
  const storage = window.sessionStorage;
  const key = "feature_flags";

  const loadFlag = async (flag, global = false) => {
    try {
      const flagsObjstr = storage.getItem(key);
      let flagsObj = {};
      try {
        flagsObj = JSON.parse(flagsObjstr);
      } catch (e2) {
        flagsObj = {};
      }
      if (!flagsObj) {
        flagsObj = {};
      }
      const prop = global ? `global_${flag}` : flag;

      if (flagsObj[prop] === true || flagsObj[prop] === false) {
        return flagsObj[prop];
      }
      const url = `api/features?flag=${flag}&global=${global}`;
      const result = await fetchWrapper({url});
      flagsObj[prop] = result;
      const updatedFlagsObjStr = JSON.stringify(flagsObj);
      storage.setItem(key,updatedFlagsObjStr);
      return result;
    } catch (e) {}
  };


  const reset = () => {
    storage.removeItem(key);
  };

  return {
    loadFlag,
    reset
  }
}

export default featureFlagService;