import {useEffect, useState, useCallback} from "react";

import featureFlagService from "./feature-flag-service";
import useStatus from "../use-status/use-status";

const defaultService = featureFlagService();

const useFeatureFlag = (flag, global = false, service = defaultService) => {

  const {
    status,
    setSavingStatus: loading,
    setErrorStatus: error,
    setSuccessStatus: done,
    setResetStatus: clearStatus
  } = useStatus(undefined, false);

  const [resetCount, setResetCount] = useState(0);
  const [loadedFlag, setLoadedFlag] = useState();


  const onKeyDown = useCallback(e => {
    if (e.keyCode === 192) {
      service.reset();
      setResetCount(cur => cur + 1);
    }
  },[service]);


  useEffect(() => {
    window.addEventListener("keydown",onKeyDown);
    return () => {
      window.removeEventListener("keydown",onKeyDown);
    }
  },[onKeyDown])

  const load = useCallback(async () => {
    try {
      loading();
      const data = await service.loadFlag(flag, global);
      setLoadedFlag(data);
      done();
    } catch (e) {
      error(e);
    }
  },[flag, global, service, loading, error, done]);

  useEffect(() => {
    load();
  }, [load, resetCount]);

  return {
    status,
    clearStatus,
    loadedFlag
  }
}

const defaultArray = [];
const useFeatureFlagCollection = (flags = defaultArray, service = defaultService) => {
  const {
    status,
    setSavingStatus: loading,
    setErrorStatus: error,
    setSuccessStatus: done,
    setResetStatus: clearStatus
  } = useStatus(undefined, false);

  const [resetCount, setResetCount] = useState(0);
  const [loadedFlags, setLoadedFlags] = useState(defaultArray);


  const onKeyDown = useCallback(e => {
    if (e.keyCode === 192) {
      service.reset();
      setResetCount(cur => cur + 1);
    }
  },[service]);


  useEffect(() => {
    window.addEventListener("keydown",onKeyDown);
    return () => {
      window.removeEventListener("keydown",onKeyDown);
    }
  },[onKeyDown]);

  const load = useCallback(async () => {
    try {
      loading();
      const newFlagResults = [];
      for (let i = 0; i < flags.length; i++) {
        const flagRequest = flags[i];
        const {flag, global = false} = flagRequest
        const result = await service.loadFlag(flag, global);
        if (result) {
          newFlagResults.push(flag);
        }
      }
      setLoadedFlags(newFlagResults);
      done();
    } catch (e) {
      error(e);
    }
  },[flags, service, loading, error, done]);

  useEffect(() => {
    load();
  }, [load, resetCount]);

  return {
    status,
    clearStatus,
    loadedFlags
  }
}

export {useFeatureFlagCollection}

export default useFeatureFlag;