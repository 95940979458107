const languages = [
  { name: "bahasa Indonesia", code: "id", code3: "ind", id:299, englishName:"Indonesian"},
  { name: "Cebuano", code: "cb", code3: "ceb", id:853, englishName:"Cebuano" }, //note cb is not official, but it is what we are using
  { name: "čeština", code: "cs", code3: "ces", id:121, englishName:"Czech" },
  { name: "dansk", code: "da", code3: "dan", id:110, englishName:"Danish" },
  { name: "Deutsch", code: "de", code3: "deu", id:150, englishName:"German" },
  { name: "Eestlane", code: "et", code3: "est", id:124, englishName:"Estonian" },
  { name: "English", code: "en", code3: "eng", id:0, englishName:"English" },
  { name: "Español", code: "es", code3: "spa", id:2, englishName:"Spanish" },
  { name: "Français", code: "fr", code3: "fra", id:140, englishName:"French" },
  { name: "Hrvatski", code: "hr", code3: "hrv", id:119, englishName:"Croatian" },
  { name: "Italiano", code: "it", code3: "ita", id:160, englishName:"Italian" },
  { name: "Latvietis", code: "lv", code3: "lav", id:153, englishName:"Latvian" },
  { name: "Lietuvis", code: "lt", code3: "lit", id:156, englishName:"Lithuanian" },
  { name: "Magyar", code: "hu", code3: "hun", id:135, englishName:"Hungarian" },
  { name: "Nederlands", code: "nl", code3: "nld", id:120, englishName:"Dutch"},
  { name: "norsk", code: "no", code3: "nor", id:170, englishName:"Norwegian" },
  { name: "Polskie", code: "pl", code3: "pol", id:166, englishName:"Polish" },
  { name: "Português", code: "pt", code3: "por", id:59, englishName:"Portuguese" },
  { name: "Română", code: "ro", code3: "ron", id:171, englishName:"Romanian" },
  { name: "Samoa", code: "sm", code3: "smo", id:890, englishName:"Samoan" },
  { name: "shqiptar", code: "sq", code3: "sqi", id:101, englishName:"Albanian" },
  { name: "Suomalainen", code: "fi", code3: "fin", id:130, englishName:"Finnish" },
  { name: "svenska", code: "sv", code3: "swe", id:180, englishName:"Swedish" },
  { name: "Tagalog", code: "tl", code3: "tgl", id:893, englishName:"Tagalog" },
  { name: "Tiếng Việt", code: "vi", code3: "vie", id:435, englishName:"Vietnamese" },
  { name: "Tonga", code: "to", code3: "ton", id:900, englishName:"Tongan" }, //
  { name: "български", code: "bg", code3: "bul", id:112, englishName:"Bulgarian" },
  { name: "Монгол", code: "mn", code3: "mon", id:363, englishName:"Mongolian" },
  { name: "Pусский", code: "ru", code3: "rus", id:173 , englishName:"Russian"},
  { name: "Українська", code: "uk", code3: "ukr", id:192, englishName:"Ukrainian"},
  { name: "հայերեն", code: "hy", code3: "hye", id:201, englishName:"Armenian"},
  { name: "ไทย", code: "th", code3: "tha", id:425, englishName:"Thai" },
  { name: "ជនជាតិខ្មែរ។", code: "km", code3: "khm", id:258, englishName:"Cambodian" },
  { name: "한국어", code: "ko", code3: "kor", id:320, englishName:"Korean" },
  { name: "中文", code: "zh", code3: "zho", id:265, englishName:"Chinese (Traditional)" },
  { name: "日本", code: "ja", code3: "jpn", id:300, englishName:"Japanese" },
];

const mapNativeNamesOnDataLanguages = (dataLanguages) => {
  const returner = [];
  dataLanguages.forEach((l) => {
    const newLang = { ...l };
    const appMatch = languages.find(
      (l) => l.code3 === newLang.code || l.code === newLang.code
    );
    if (appMatch) {
      newLang.display = appMatch.name;
    } else {
      newLang.display = newLang.name;
    }
    returner.push(newLang);
  });
  return returner;
};
export { mapNativeNamesOnDataLanguages };

export default languages;
