const stringsKey = (language) => `strings-${language}`;

export const syncStringsToLocalStorage = (language, strings) => {
  const storage = window.sessionStorage;

  const key = stringsKey(language);

  let existingString = storage.getItem(key);
  if (!existingString) {
    existingString = "{}";
  }
  let existing = {};
  try {
    existing = JSON.parse(existingString);
  } catch (e) {
    existing = {};
  }
  const saver = { ...existing, ...strings };

  const saveJSON = JSON.stringify(saver);
  storage.setItem(key, saveJSON);
};

export const getStringsFromLocalStorage = (language) => {

  const storage = window.sessionStorage;
  const key = stringsKey(language);

  let existingString = storage.getItem(key);
  if (!existingString) {
    existingString = "{}";
  }
  try {
    return JSON.parse(existingString);
  } catch (e) {
    return {};
  }

};
