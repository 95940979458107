import React from "react";

import Stack from "@churchofjesuschrist/eden-stack";
import { Link } from "react-router-dom";

import useTranslation from "../../../utils/use-translations";
import FeatureFlags from "../../generic/feature-flags/feature-flags";

const stringGroups = [
  {
    name: "shared",
    keys: ["systemAlerts", "releaseNotes"]
  }
];

const AdminMenu = ({ close, flagService }) => {
  const { managedStrings } = useTranslation(stringGroups);

  return (
    <Stack>
      <FeatureFlags flag="GlobalNotifications" service={flagService}>
        <Link onClick={close} to="/admin">{managedStrings.systemAlerts}</Link>
      </FeatureFlags>
      <FeatureFlags flag="ReleaseNotes" service={flagService}>
        <Link onClick={close} to="/releasenotes">{managedStrings.releaseNotes}</Link>
      </FeatureFlags>
    </Stack>
  )
}

export default AdminMenu;