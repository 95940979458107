import React from 'react';

import {createRoot} from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom';

import appRoutes from './components/pages/app-routes';
import registerServiceWorker from './registerServiceWorker';


const router = createBrowserRouter(createRoutesFromElements(appRoutes))

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<RouterProvider router={router}/>);

registerServiceWorker();
