const stringHasValue = (s) => {
  if (s === undefined || s === null) {
    return false;
  }
  if (typeof s.toLowerCase != "function") {
    return null;
  }

  return s.trim() !== "";
};

const htmlMarkupHasValue = (html) => {
  const stripped = html.replace(/(<([^>]+)>)/gi, "");
  return stringHasValue(stripped);
}
export {htmlMarkupHasValue};
export default stringHasValue;
