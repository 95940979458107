import React, { lazy } from "react";

import { Route } from "react-router-dom";

const FaqPage = lazy(() => import("./faq-page"));
const InstituteOnlinePage = lazy(() => import("./institute-online-page"));
const InstituteTypesPage = lazy(() => import("./institute-types-page"));
const SeminaryPage = lazy(() => import("./seminary-page"));
const SupportPage = lazy(() => import("./support-page"));
const WelcomePage = lazy(() => import("./welcome-page"));

const PublicContentRoutes = <>
  <Route exact path="/faq" element={<FaqPage />} />,
  <Route exact path="/instituteOnline" element={<InstituteOnlinePage />} />,
  <Route exact path="/instituteTypes" element={<InstituteTypesPage />} />,
  <Route exact path="/seminary" element={<SeminaryPage />} />,
  <Route exact path="/support" element={<SupportPage />} />,
  <Route exact path="/welcome" element={<WelcomePage />} />,
</>;

export default PublicContentRoutes;
