import {
  getStringsFromLocalStorage,
  syncStringsToLocalStorage } from "./local-storage-synchronization";
import fetchWrapper from "../fetchWrapper";

const loadTranslations = async (language = "en", groups = ["shared"]) => {

  const strings = getStringsFromLocalStorage(language);
  const groupsToLoad = [];
  groups.forEach(f => {
    if (!strings[f]) {
      groupsToLoad.push(f);
    }
  });
  if (groupsToLoad.length > 0) {
    let url = `api/translations?language=${language}`;

    const groupsQuery = groupsToLoad.reduce((previous,group) => `${previous}&groups=${group}`,"");

    url = `${url}${groupsQuery}`;
    const loaded = await fetchWrapper({url:url});

    const result = {...strings,...loaded};
    syncStringsToLocalStorage(language,result);
    return result;


  } else {
    return strings;
  }
};

export default loadTranslations;
