import { sessionBackedLoad } from "../../../utils/cached-data-load";
import fetchWrapper from "../../../utils/fetchWrapper";

const releaseNotesService = () => {
  const checkReleaseNotes = async () => {
    try {
      const result = await fetchWrapper({
        url: "/api/releaseNotes/check"
      });
      return result;
    } catch (e) {
      return false;
    }
  }

  const loadReleaseNotes = async () => {
    return sessionBackedLoad({
      url: "/api/releaseNotes",
      key: "release_notes"
    });
  }

  return {
    checkReleaseNotes,
    loadReleaseNotes
  }
}

export default releaseNotesService;