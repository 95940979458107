import cookie from "cookie";

const tokensFromCookie = (cookies = []) => {
  let srcObj = cookie.parse(document.cookie);

  let result = cookies.reduce(
    (obj, cookie) => ({
      ...obj,
      [cookie]: srcObj[cookie],
    }),
    {}
  );
  return result;
};

export default tokensFromCookie;