import 'devextreme/dist/css/dx.light.css';
import React, { Suspense } from 'react';


import Fonts from "@churchofjesuschrist/eden-fonts";
import EdenNormalize from "@churchofjesuschrist/eden-normalize";
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

import ErrorBoundary from './components/generic/error-boundary/error-boundary';
import LoadingSpinner from './components/generic/loading-spinner/loading-spinner';
import MasterLayout from './components/global-layout/master-layout/master-layout';


const App = ({children}) => (
  <>
    <Helmet>
      <Fonts />
    </Helmet>
    <EdenNormalize />
    <MasterLayout>
      <Suspense fallback={<LoadingSpinner large/>}>
        <ErrorBoundary>
          <Outlet/>
        </ErrorBoundary>
      </Suspense>
    </MasterLayout>
  </>
);

export default App;
